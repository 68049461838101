<template>
<h1>Redirect to dashboard</h1>
</template>

<script>
import {mapGetters} from "vuex";
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
    name: "Access",
    computed: {
        ...mapGetters({
            globalConfig: 'globalConfig/get',
        }),
    },
    mixins: [globalConfigMixin],
    props: {
        ssoAuthToken: { type: String, default: '' },
        ssoAccountId: { type: String, default: '' },
        redirectUrl: { type: String, default: '' },
        config: { type: String, default: '' },
        email: { type: String, default: '' },
        auth_token: { type: String, default: '' },
    },
    created() {
        console.log(this.auth_token, 'this.auth_token============')
        console.log(this.email, 'this.email============')
        if (this.auth_token) {
            this.login();
        }
    },
    methods: {
        login() {
            console.log('inside lofin 000000000000000')
            const credentials = {
                email: this.email
                    ? decodeURIComponent(this.email)
                    : this.credentials.email,
                auth_token: this.auth_token,
            };
            console.log(credentials, 'credentials-------------')
            this.$store
                .dispatch('login', credentials)
                .then(() => {

                    this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
                })
                .catch(response => {
                    // Reset URL Params if the authentication is invalid
                    if (this.email) {
                        window.location = '/app/login';
                    }

                    if (response && response.status === 401) {
                        const { errors } = response.data;
                        const hasAuthErrorMsg =
                            errors &&
                            errors.length &&
                            errors[0] &&
                            typeof errors[0] === 'string';
                        if (hasAuthErrorMsg) {
                            this.showAlert(errors[0]);
                        } else {
                            this.showAlert(this.$t('LOGIN.API.UNAUTH'));
                        }
                        return;
                    }
                    this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
                });
        },
    }
}
</script>

<style scoped>

</style>
