/* eslint arrow-body-style: 0 */
import WelcomeView from './WelcomeView';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/welcome'),
      name: 'welcome',
      roles: ['administrator', 'agent'],
      component: WelcomeView,
      props: () => {
        return { inboxId: 0 };
      },
    },
  ],
};
