<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.SMS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.SMS.DESC')"
    />
    <sms-edge></sms-edge>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import BandwidthSms from './BandwidthSms.vue';
import Twilio from './Twilio';
import SmsEdge from './SmsEdge.vue'

export default {
  components: {
    PageHeader,
    Twilio,
    BandwidthSms,
    SmsEdge
  },
  data() {
    return {
      provider: 'twilio',
    };
  },
};
</script>
