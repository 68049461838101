<template>
  <div class="card">
    <div class="d-flex p-normal">
      <img :src="image" alt="test" />
      <h5> {{message}} </h5>
    </div>
  </div>
</template>

<script>

export default {
  props:{
    image:{
      type:String,
      default:''
    },
    message:{
      type: String,
      default: ''
    }
  }

}

</script>

<style scoped>
  .d-flex{
    display:flex;
  }
</style>
