import ApiClient from '../ApiClient';

class WhatsAppWeb extends ApiClient {
    constructor() {
        super('channels/whatsapp_web_channel', { accountScoped: true });
    }

    saveWhatsAppWebChannel(data) {
        return axios.post(`${this.baseUrl()}/inboxes/whatsapp_web_channel`, data)
    }

    saveWhatsAppWebInbox(data) {
        return axios.post(`${this.baseUrl()}/inboxes/whatsapp_web_inbox`, data)
    }
}

export default new WhatsAppWeb();
