<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.email.$error }">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.LABEL') }}
          <v-select id="email-servers" v-model.trim="email" :options="emailServers" :loading="loading" taggable label="from_email" class="custom" placeholder="Select/Add Email">
            <template #list-footer>
              <li style="text-align: center">Press enter to add new email.</li>
            </template>
            <template slot="option" slot-scope="option">
              <span :class="option.icon"></span>
              {{ option.from_email }} - {{option.name}}
          </template>
          </v-select>
          <span v-if="$v.email.$error" class="message error-message">{{
            $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.ERROR')
          }}</span>
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.SUBTITLE') }}
        </p>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import vSelect from 'vue-select'

const validEmail = (value) => {
    if (value !== null && value.from_email) {
      return value.from_email.includes('@')
    }

    return false
  };

export default {
  components: {
    PageHeader,
    vSelect,
  },
  
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      email: '',
      emailServers : [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    email: { required, validEmail },
  },
  mounted(){
    this.emailList()
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const emailChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              type: 'email',
              email: this.email.from_email,
              imap_enabled: this.email.options ? true : false,
              imap_login: this.email.options ? this.email.smtp_username : null,
              imap_password: this.email.options ? this.email.smtp_password : null,
              imap_address: this.email.options ? this.email.options.imap_host : null,
              imap_port: this.email.options ? this.email.options.imap_port : null,
              imap_enable_ssl: this.email.options ? true : false,
              imap_inbox_synced_a: this.email.options ? new Date().toISOString() : undefined,
              smtp_enabled: this.email.smtp_username ? true : false,
              smtp_login: this.email.smtp_username,
              smtp_password: this.email.smtp_password,
              smtp_address: this.email.smtp_host,
              smtp_port: this.email.smtp_port,
              smtp_domain: this.email.smtp_host,
              smtp_enable_starttls_auto: this.email.smtp_port==587 ? true : false,
              smtp_enable_ssl_tls: this.email.smtp_port==587 ? false : true,
              smtp_openssl_verify_mode: "none",
              smtp_authentication: "login",
            },
          }
        );
        console.log(this.email)
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: emailChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
    async emailList() {
      var res = await this.$store.dispatch('inboxes/getEmailServerList')
      this.emailServers = res
      this.loading = false
    },
  },
};
</script>

<style scoped lang="scss">
.help-text{
  font-weight: 400 !important;
  margin-top: 4px !important;
}
.error-message {
  margin-top: 3px;
}
.error{
  #email-servers{
    border: 1px solid red !important;
    border-radius: 4px !important;
  }
}

</style>
