<template>
  <div class="bg-light p-normal w-full">
    <h4>Welcome to SMSEdge!</h4>
    <div class="card">
      <div class="d-flex  p-normal">
        <img :src="globalConfig.logoThumbnail" alt="test" />
        <h5 class="mb-0 align-items-center ml-75 pointer" @click="openInbox"> Start using SMSEdge Inbox </h5>
      </div>
    </div>
    <div class="card">
      <div class="d-flex align-items-center p-normal">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="chat-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
        </svg>
        <div class="ml-75">
          <h5> See how to User SmsEdge Inbox - <span @click="openURL" class="primary pointer"> Guide</span> </h5>
        </div>
      </div>
    </div>
    <div>
      <h4>Video</h4>
      <div class="d-flex justify-content-center">
        <youtube video-id="gEkYFUDdQAQ"></youtube>
      </div>
    </div>
  </div>
</template>

<script>
  
  import {mapGetters} from 'vuex'
  import Card from './components/Card'
  import adminMixin from '../../../mixins/isAdmin';

  export default {
    computed: {
    ...mapGetters({
        
        globalConfig: 'globalConfig/get',
        
      }),
    },
    mixins:[adminMixin],
    methods: {
      openURL(){
        window.open("https://doc.clickup.com/d/h/6arye-148/6d9a6a9e05f9ed2/6arye-7408", '_blank').focus();
      },
      openInbox(){
        var accountId = window.location.pathname.split('/')[3]
        var pathName = this.isAdmin ? `app/accounts/${accountId}/settings/inboxes/new` : `app/accounts/${accountId}/dashboard`
        window.location.pathname = pathName
      }
    },
  }
</script>

<style scoped>
  .d-flex{
    display:flex;
  }
  .justify-content-center{
    justify-content: center;
  }
  .chat-icon{
    height: 24px;
    width: 24px;
    color: #f05d55;
  }
  .ml-75{
    margin-left: .75rem;
  }
  .align-items-center{
    align-items: center;
  }
  .mb-0{
    margin-bottom:0px;
  }
  .primary{
      color: #007BFF;   
  }
  .pointer{
    cursor: pointer;
  }
</style>