<!-- Deprecated in favour of separate files for SMS and Whatsapp and also to implement new providers for each platform in the future-->
<template>
  <form class="row" @submit.prevent="createChannel()">
    <div class="medium-8 columns">
      <label :class="{ error: $v.channelName.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CHANNEL_NAME.LABEL') }}
        <input
          v-model.trim="channelName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CHANNEL_NAME.PLACEHOLDER')"
          @blur="$v.channelName.$touch"
        />
        <span v-if="$v.channelName.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CHANNEL_NAME.ERROR')
        }}</span>
      </label>
    </div>
    <div class="medium-8 columns">
      <label :class="{ error: $v.country.$error }">{{
        $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.COUNTRY.LABEL')
        }}<select v-model="country">
          <option value="" disabled selected hidden>{{
            $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.COUNTRY.PLACEHOLDER')
            }}</option>
          <option value="USA">
            United States
          </option>
        </select>
        <span v-if="$v.country.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.COUNTRY.ERROR')
        }}</span>
      </label>
    </div>

    <div class="medium-8 columns">
      <label :class="{ error: $v.campaign.$error }">{{
        $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CAMPAIGN.LABEL')
        }}<select v-model="campaign" @change="selctedCampaign" :class="$v.campaign.$error? '' : 'mb-0'">
            <option value="" disabled selected hidden>{{campaigns.length == 0? emptyCampaignsResponse? 'No campaigns found' : 'Loading ...' : 'Pleasse Choose'}}.</option>
            <option v-for="caa in campaigns" :value="caa.campaign_id">{{caa.campaign_id}} </option>
        </select>
        <span v-if="$v.campaign.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CAMPAIGN.ERROR')
        }}</span>
        <span class="message"> 
          {{$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CAMPAIGN.HINT')}} 
          <strong class="pointer text-blue" @click="openRegisterLink"> {{$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.CAMPAIGN.HERE')}} </strong> 
        </span>
      </label>
    </div>

    <div class="medium-8 columns" :class="$v.phoneNumber.$error? '' : 'm-2'">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.PHONE_NUMBER.LABEL')}}
        <select v-model="phoneNumber" :class="$v.phoneNumber.$error? '' : 'mb-0'" :disabled="phoneNumbers.length == 0">
            <option value="" disabled selected hidden>{{phoneNumberPlaceholder()}}</option>
            <option v-for="number in phoneNumbers" :value="number.number">{{number.number}} </option>
        </select>
        <span v-if="$v.phoneNumber.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.PHONE_NUMBER.ERROR')
        }}</span>
        <span  class="message text-red" v-if="emptyNumberResponse"> 
          {{$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.PHONE_NUMBER.MESSAGE')}}
          <strong @click="showModal=true" class="pointer text-blue" > {{$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.PHONE_NUMBER.HERE')}} </strong> 
        </span>
      </label>
    </div>

    <div class="medium-12 columns">
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.SUBMIT_BUTTON')"
      />
    </div>
    <rent-number-modal  :show="showModal" :inProcess="inProcess" :campaign="campaign" @in-process="inProcess=true" @close-rent-number-modal="showModal=false"/>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import RentNumberModal from '../components/RentNumberModal'

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');
const selectOption = (value = '')=> value!=""

export default {
  mixins: [alertMixin],
  data() {
    return {
      country:'',
      accountSID: '',
      authToken: '',
      channelName: '',
      phoneNumber: '',
			campaign:'',
      campaigns: [],
      phoneNumbers: [],
      emptyNumberResponse: false,
      showModal: false,
      inProcess: false,
      emptyCampaignsResponse: false
    };
  },
  components:{
    RentNumberModal
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      getCampaignsFromSmsEdge: 'inboxes/getCampaignsFromSmsEdge',
      getCampaignsPhoneNumber: 'inboxes/getCampaignsPhoneNumber'
    }),
  },
  validations: {
    channelName: {required},
    phoneNumber: { required },
		campaign: {required, selectOption},
		country: {required, selectOption}
  },
	watch:{
		campaign(newVal){
			this.getPhoneNumbers(newVal)
		},
    getCampaignsPhoneNumber(newData){
      if(newData.hasOwnProperty('in_process')) return;
      this.phoneNumbers = newData
      if(newData.length>0){
        this.inProcess = false
        this.emptyNumberResponse = false
      }

    }
	},
  mounted(){
    // console.log(this.getCampaingsFromEdge)
    this.getCampaigns()
  },
  methods: {
     phoneNumberPlaceholder(){
      if (this.campaign == ""){
        return "Please select a camapaign"
      }else if(this.phoneNumbers.length == 0){
          return this.emptyNumberResponse? "No Number Found" : "Loading ..."
      }else{
        return "Please Choose The Phone Number"
      }
    }, 
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const smsEdgeChannel = await this.$store.dispatch(
          'inboxes/createSmsEdgeChannnel',
          {
            name: this.channelName,
            channel: {
              type:'sms_edge',
              campaign_id: this.campaign,
              phone_number: this.phoneNumber,
              country: this.country
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: smsEdgeChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.SMS_EDGE_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    async getCampaigns() {
        try {
        var data = await this.$store.dispatch('inboxes/getCampaignsFromSmsEdge');
        this.campaigns = data
        if(this.campaigns.length == 0){
          this.emptyCampaignsResponse = true
        }
        } catch (error) {
        this.showAlert("Could not fetch campaigns , Please Contact Administrator");
        this.emptyCampaignsResponse = true

      }
    },
		async getPhoneNumbers(campaignId) {
        try {
        var data = await this.$store.dispatch('inboxes/getNumbersFromSmsEdge', campaignId);
        if( data.phone_numbers.length == 0 ) {
          this.emptyNumberResponse = true
        } else {
          this.phoneNumbers = data.phone_numbers
        }
        this.inProcess = data.in_process
        } catch (e) {
          var error = JSON.parse(e.message)
          console.log(error)
        if(error.status == '404') {
          this.emptyNumberResponse = true
          this.inProcess = error.data.in_process
          this.showAlert(error.data.phone_numbers[0])
        } else {
          this.showAlert("Could not fetch phone numbers , Please Contact Administrator");
          
        }
      }
    },
    openRegisterLink(){
      window.location.href="https://app.smsedge.com/us_registry/campaigns"
    },
    selctedCampaign(){
      this.$store.dispatch('inboxes/setSelectedCampaign', this.campaign)
    }
  },
};
</script>

<style lang="scss" scoped >

@import '~foundation-sites/scss/util/util';

.pointer{
  cursor: pointer;
}
.text-red{
  color: red;
}
.text-blue{
  color: #1f93ff!important;
}
.mb-0{
  margin-bottom : 0px;
}
.mb-2{
  margin-bottom : 1.6rem;
}
.mt-2{
  margin-top : 1.6rem;
}
.m-2{
  margin-top : 1.6rem;
  margin-bottom : 1.6rem;
}
</style>