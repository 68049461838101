import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import InboxesAPI from '../../api/inboxes';
import WebChannel from '../../api/channel/webChannel';
import FBChannel from '../../api/channel/fbChannel';
import TwilioChannel from '../../api/channel/twilioChannel';
import WhatsAppWeb from "../../api/channel/whatsAppWeb";
import SmsEdge from '../../api/smsEdge';
import EmailServer from '../../api/emailServer';

const buildInboxData = inboxParams => {
  const formData = new FormData();
  const { channel = {}, ...inboxProperties } = inboxParams;
  Object.keys(inboxProperties).forEach(key => {
    formData.append(key, inboxProperties[key]);
  });
  const { selectedFeatureFlags, ...channelParams } = channel;
  // selectedFeatureFlags needs to be empty when creating a website channel
  if (selectedFeatureFlags) {
    if (selectedFeatureFlags.length) {
      selectedFeatureFlags.forEach(featureFlag => {
        formData.append(`channel[selected_feature_flags][]`, featureFlag);
      });
    } else {
      formData.append('channel[selected_feature_flags][]', '');
    }
  }
  Object.keys(channelParams).forEach(key => {
    formData.append(`channel[${key}]`, channel[key]);
  });
  return formData;
};

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isUpdatingAutoAssignment: false,
    isDeleting: false,
    isUpdatingIMAP: false,
    isUpdatingSMTP: false,
  },
    whatsappWebRecords: [],
    campaings: [],
    campaignsPhoneNumber:[],
    selectedCampaign: '',
    emailServerList:[]
};

export const getters = {
  getInboxes($state) {
    return $state.records;
  },
  getNewConversationInboxes($state) {
    return $state.records.filter(inbox => {
      const {
        channel_type: channelType,
        phone_number: phoneNumber = '',
      } = inbox;

      const isEmailChannel = channelType === INBOX_TYPES.EMAIL;
      const isSmsChannel =
        channelType === INBOX_TYPES.TWILIO &&
        phoneNumber.startsWith('whatsapp');
      return isEmailChannel || isSmsChannel;
    });
  },
  getInbox: $state => inboxId => {
    const [inbox] = $state.records.filter(
      record => record.id === Number(inboxId)
    );
    return inbox || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getWebsiteInboxes($state) {
    return $state.records.filter(item => item.channel_type === INBOX_TYPES.WEB);
  },
  getTwilioInboxes($state) {
    return $state.records.filter(
      item => item.channel_type === INBOX_TYPES.TWILIO
    );
  },
  getSMSInboxes($state) {
    return $state.records.filter(
      item =>
        item.channel_type === INBOX_TYPES.SMS ||
        (item.channel_type === INBOX_TYPES.TWILIO && item.medium === 'sms')
    );
  },
  dialogFlowEnabledInboxes($state) {
    return $state.records.filter(
      item => item.channel_type !== INBOX_TYPES.EMAIL
    );
  },

  getWhatsappWebChannel($state) {
    return $state.whatsappWebRecords;
  },
  getCampaignsPhoneNumber($state) {
    return $state.campaignsPhoneNumber;
  }
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxesAPI.get();
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_INBOXES, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
    }
  },
  createChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createWebsiteChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(buildInboxData(params));
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createTwilioChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await TwilioChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createFBChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createSmsEdgeChannnel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await InboxesAPI.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  updateInbox: async ({ commit }, { id, formData = true, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingAutoAssignment: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
      throw new Error(error);
    }
  },
  updateInboxIMAP: async (
    { commit },
    { id, formData = true, ...inboxParams }
  ) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingIMAP: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingIMAP: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingIMAP: false,
      });
      throw new Error(error);
    }
  },
  updateInboxSMTP: async (
    { commit },
    { id, formData = true, ...inboxParams }
  ) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingSMTP: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingSMTP: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingSMTP: false,
      });
      throw new Error(error);
    }
  },
  delete: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await InboxesAPI.delete(inboxId);
      commit(types.default.DELETE_INBOXES, inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  reauthorizeFacebookPage: async ({ commit }, params) => {
    try {
      const response = await FBChannel.reauthorizeFacebookPage(params);
      commit(types.default.EDIT_INBOXES, response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  deleteInboxAvatar: async (_, inboxId) => {
    try {
      await InboxesAPI.deleteInboxAvatar(inboxId);
    } catch (error) {
      throw new Error(error);
    }
  },

  getWhatsappCode: async ({commit}, params) => {
    try {
        commit(types.default.SET_WHATSAPP_WEB_CHANNEL, params);
        return params;
    } catch (error) {
        throw new Error(error);
    }
  },

  createWhatsAppChannel: async ({ commit }, params) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
    try {
        const response = await WhatsAppWeb.saveWhatsAppWebChannel(params);
        console.log(response.data, 'response.data --------')
        commit(types.default.ADD_WHATSAPP_WEB_CHANNEL, response.data);
        commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });

        return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
        throw new Error(error.response.data.error);
    }
  },

    createWhatsAppInbox: async ({ commit }, params) => {
      try {
          const response = await WhatsAppWeb.saveWhatsAppWebInbox(params);
          console.log(response.data, 'response.data ---inbox-----')
          return response.data;
      } catch (error) {
          throw new Error(error);
      }
  },
  getCampaignsFromSmsEdge:  async ({ commit }) => {
      try {
          const response = await SmsEdge.getCampaigns();
          console.log(response.data, 'response.data ---inbox-----')
          commit(types.default.SET_SMS_CAMPAIGNS,response.data.campaigns)
          return response.data.campaigns;
      } catch (error) {
          throw new Error(error);
      }
  },
  getNumbersFromSmsEdge:  async ({ commit },campaignId) => {
      try {
          const response = await SmsEdge.getPhoneNumber(campaignId);
          console.log(response)
          console.log(response.data, 'response.data ---inbox-----')
          commit(types.default.SET_CAMPAIGNS_PHONE_NUMBERS,response.data.phone_numbers)
          return response.data;
      } catch (error) {
          throw new Error(JSON.stringify(error.response));
      }
  },
  receivedNumberFromSmsEdge: async ({ commit },data) => {
      try {
          console.log("compare" , [state.selectedCampaign , data.campaign_id])
          if(state.selectedCampaign == data.campaign_id) {
            commit(types.default.SET_CAMPAIGNS_PHONE_NUMBERS,[...state.campaignsPhoneNumber,...data.numbers])
          }
      } catch (error) {
          throw new Error(error);
      }
  },
  setSelectedCampaign: async ({commit},campaign)=>{
    commit(types.default.SET_SELECTED_CAMPAIGN,campaign)
  },
  getEmailServerList: async ({ commit }) => {
    try {
      const response = await EmailServer.getEmailList();
      commit(types.default.SET_EMAIL_SERVERS,response.data)
      
      return response.data.email_servers;
    } catch (error) {
      
    }
  },
};

export const mutations = {
  [types.default.SET_INBOXES_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_INBOXES]: MutationHelpers.set,
  [types.default.SET_INBOXES_ITEM]: MutationHelpers.setSingleRecord,
  [types.default.ADD_INBOXES]: MutationHelpers.create,
  [types.default.EDIT_INBOXES]: MutationHelpers.update,
  [types.default.DELETE_INBOXES]: MutationHelpers.destroy,

    [types.default.ADD_WHATSAPP_WEB_CHANNEL]: ($state, data) => {
        console.log(data, 'data')
        $state.whatsappWebRecords = data;
        console.log($state.whatsappWebRecords, 'whatsappWebRecords')
    },
    [types.default.SET_WHATSAPP_WEB_CHANNEL]: ($state, data) => {
      if($state.whatsappWebRecords.phone_number == data.phone_number ){
        $state.whatsappWebRecords = data;
        console.log($state.whatsappWebRecords, 'SET_WHATSAPP_WEB_CHANNEL -------')
      }else{
        console.log( 'SET_WHATSAPP_WEB_CHANNEL not working-------')
      }
      
    },
    [types.default.SET_SMS_CAMPAIGNS]:($state,data) => {
      $state.campaings = data
    },
    [types.default.SET_CAMPAIGNS_PHONE_NUMBERS]:($state,data) => {
      $state.campaignsPhoneNumber = data
    },
    [types.default.SET_SELECTED_CAMPAIGN]:($state,data) => {
      $state.selectedCampaign = data
    },
    [types.default.SET_EMAIL_SERVERS]:($state,data) => {
      $state.emailServerList = data
    }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
