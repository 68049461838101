import ApiClient from './ApiClient';

class EmailServer extends ApiClient {
  constructor() {
    super('email', { accountScoped: true });
  }

  getEmailList() {
    return axios.get(`${this.url}/email_list`)
  }
}

export default new EmailServer();
