/* global axios */
import ApiClient from './ApiClient';

class SmsEdgeApi extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getCampaigns() {
    return axios.get(`${this.url}/channels/sms_edge/campaigns`);
  }

  getPhoneNumber(campaignId) {
    return axios.get(`${this.url}/channels/sms_edge/phone_numbers?campaign_id=${campaignId}`)
  }

  createPhoneNumber(data) {
    return axios.post(`${this.url}/channels/sms_edge/create_phone_numbers`,data)
  }
}

export default new SmsEdgeApi();
