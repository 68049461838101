<template>
    <div class="wizard-body small-9 columns">
        <page-header
                :header-title="$t('INBOX_MGMT.ADD.WHATSAPP_WEB.TITLE')"
                :header-content="$t('INBOX_MGMT.ADD.WHATSAPP_WEB.DESC')"
        />

        <form class="row" v-if="!getWhatsappChannel.hasOwnProperty('qr_code') || !getWhatsappChannel.qr_code || getWhatsappChannel.status == 'disconnected'" @submit.prevent="createChannel()">
          <div class="medium-8 columns">
              <label :class="{ error: $v.inboxName.$error }">
                  {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.INBOX_NAME.LABEL') }}
                  <input
                          v-model.trim="inboxName"
                          type="text"
                          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP_WEB.INBOX_NAME.PLACEHOLDER')"
                          @blur="$v.inboxName.$touch"
                  />
                  <span v-if="$v.inboxName.$error" class="message">
                    {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.INBOX_NAME.ERROR') }}
                  </span>
              </label>
          </div>

          <div class="medium-8 columns">
              <label :class="{ error: $v.phoneNumber.$error }">
                  {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.PHONE_NUMBER.LABEL') }}

                    (e.g  {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.PHONE_NUMBER.FORMATE') }})

                  <input
                          v-model.trim="phoneNumber"
                          type="text"
                          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP_WEB.PHONE_NUMBER.PLACEHOLDER')"
                          @blur="$v.phoneNumber.$touch"
                  />
                  <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.PHONE_NUMBER.ERROR') }}
          </span>
              </label>
          </div>


          <div class="medium-12 columns">
              <woot-submit-button
                      :disabled="!phoneNumber || !inboxName || uiFlags.isCreating ||  ( getWhatsappChannel.hasOwnProperty('qr_code') && !getWhatsappChannel.qr_code)"
                      :loading="uiFlags.isCreating ||  ( getWhatsappChannel.hasOwnProperty('qr_code') && !getWhatsappChannel.qr_code)"
                      :button-text="$t('INBOX_MGMT.ADD.WHATSAPP_WEB.AUTHORIZE_BUTTON')"
              />
          </div>
        </form>
        <div class="medium-10 columns" v-else>
            <label> {{ $t('INBOX_MGMT.ADD.WHATSAPP_WEB.QR_CODE.LABEL') }} </label>
            <qrcode-vue :value="getWhatsappChannel.qr_code" :size="size" level="H"></qrcode-vue>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import QrcodeVue from 'qrcode.vue'

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');

export default {
    components: {
        PageHeader,
        QrcodeVue
    },
    mixins: [alertMixin],
    data() {
        return {
            inboxName: '',
            phoneNumber: '',
            show: false,
            size: 270,
        };
    },
    computed: {
        ...mapGetters({
            uiFlags: 'inboxes/getUIFlags',
            globalConfig: 'globalConfig/get',
            getWhatsappChannel: 'inboxes/getWhatsappWebChannel'
        }),
    },
    validations: {
        inboxName: { required },
        phoneNumber: { required, shouldStartWithPlusSign },
    },
    watch: {
        getWhatsappChannel(newData, oldData) {
            console.log(newData,'newData')
            if (newData.status === "connected") {
                this.agentSetup()
                this.showAlert(
                    this.$t('INBOX_MGMT.ADD.WHATSAPP_WEB.QR_CODE.SUCCESS')
                );
            }
        },
    },
    methods: {
        onClose() {
            this.show = false;
        },
        onClick() {
            this.show = true;
        },

        async agentSetup() {
            try {
                const whatsappInbox = await this.$store.dispatch(
                    'inboxes/createWhatsAppInbox',
                    {
                        name: this.inboxName,
                        channel: {
                            id: this.getWhatsappChannel?.id,
                        },
                    }
                );
                console.log(whatsappInbox, 'whatsappInbox ---------')
                router.replace({
                    name: 'settings_inboxes_add_agents',
                    params: {
                        page: 'new',
                        inbox_id: whatsappInbox.id,
                    },
                });
                await this.$store.dispatch('inboxes/get');
            } catch (error) {
                this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP_WEB.API.ERROR_MESSAGE'));
            }
        },

        async createChannel() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                const whatsappChannel = await this.$store.dispatch(
                    'inboxes/createWhatsAppChannel',
                    {
                        name: this.inboxName,
                        channel: {
                            phone_number: this.phoneNumber,
                        },
                    }
                );
                this.show = true
            } catch (error) {
                this.showAlert(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.modal-container {
    box-shadow: 0 0.8rem 1.6rem rgb(50 50 93 / 8%), 0 0.4rem 1.2rem rgb(0 0 0 / 7%);
    background-color: #fff;
    border-radius: 0.4rem;
    max-height: 100%;
    overflow: auto;
    position: relative;
    width: 35rem;
    height: 30rem;
}

</style>
