<template>
  <woot-modal
    :show="show"
    :on-close="() => $emit('close-rent-number-modal')"
    class="account-selector--modal"
  >
    <div v-if="inProcess" class="column content-box mb-2">
     <woot-modal-header
        header-title="Rent Numbers"
        header-content="Your request to rent new number(s) is already in process , please wait for it completion "
      />
      
    </div>
    <div v-else class="column content-box">
      <woot-modal-header
        header-title="Rent Numbers"
        header-content="How many numbers would you like to assign? "
      />
      <div class="custom-padding">
        <p> The numbers assigned can be used only for this campaign. </p>
        <p> Each number costs €0.99/month per number. </p>
      </div>
      <form class="row" @submit.prevent="rentNumber">
        <div class="medium-12 columns">
          <label :class="{ error: $v.rentNumberAmount.$error }">
            Amount of phones you want to rent
            <input
              v-model.trim="rentNumberAmount"
              type="number"
              min=1
              placeholder="Enter Amount"
              @input="$v.rentNumberAmount.$touch"
            />
          </label>
        </div>
        <div class="modal-footer medium-12 columns">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.rentNumberAmount.$invalid ||
                  $v.rentNumberAmount.$invalid ||
                  uiFlags.isCreating || 
                  campaign == ''
              "
              button-text="Rent Number"
              :loading="uiFlags.isCreating"
              button-class="large expanded"
            />
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength,minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SmsEdgeApi from '../../../../../api/smsEdge.js'

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    campaign:{
      type: String,
      default: ''
    },
    inProcess:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      rentNumberAmount: '',
    };
  },
  validations: {
    rentNumberAmount: {
      required,
      minValue: 1
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
  },
  methods: {
   rentNumber(){
    if(this.campaign == '') return;
    SmsEdgeApi.createPhoneNumber({amount:this.rentNumberAmount,campaign_id:this.campaign}).then(res=>{
      console.log(res)
      this.$emit("in-process")
      this.showAlert(res.data.data)
    }).catch(res=>{
      this.showAlert("could not proceed please try again later")
    })
    this.$emit('close-rent-number-modal')
   }
  },
};
</script>
<style lang="scss" scoped>
.alert-wrap {
  font-size: var(--font-size-small);
  margin: var(--space-medium) var(--space-large) var(--space-zero);

  .callout {
    align-items: center;
    border-radius: var(--border-radius-normal);
    display: flex;
  }
}
.mb-2{
  margin-bottom : 2rem;
}
.custom-padding{
  padding: 1em 3.2em;
}
.icon-wrap {
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}
</style>
