<template>
  <div class="primary--sidebar">
    <logo
      :source="logoSource"
      :name="installationName"
      :account-id="accountId"
    />
    <nav class="menu vertical">
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <div class="button clear button--only-icon menu-item "  v-tooltip.right="`documentation`" @click= "openDocs">
        <span class="button__content">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M70 250 l0 -230 180 0 180 0 0 168 0 168 -63 62 -63 62 -117 0 -117
            0 0 -230z m220 150 l0 -60 60 0 60 0 0 -150 0 -150 -160 0 -160 0 0 210 0 210
            100 0 100 0 0 -60z m65 0 l39 -40 -42 0 -42 0 0 40 c0 22 1 40 3 40 2 0 21
            -18 42 -40z"/>
            <path d="M150 270 c0 -6 40 -10 100 -10 60 0 100 4 100 10 0 6 -40 10 -100 10
            -60 0 -100 -4 -100 -10z"/>
            <path d="M150 210 c0 -6 33 -10 80 -10 47 0 80 4 80 10 0 6 -33 10 -80 10 -47
            0 -80 -4 -80 -10z"/>
            <path d="M150 150 c0 -6 40 -10 100 -10 60 0 100 4 100 10 0 6 -40 10 -100 10
            -60 0 -100 -4 -100 -10z"/>
            </g>
          </svg>

        </span>
      </div>
      <div class="button clear button--only-icon menu-item "  v-tooltip.right="`app.smsedge.com`" @click= "backToApp">
        <span class="button__content">
          <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.295 19.716a1 1 0 0 0 1.404-1.425l-5.37-5.29h13.67a1 1 0 1 0 0-2H6.336L11.7 5.714a1 1 0 0 0-1.404-1.424l-6.924 6.822a1.25 1.25 0 0 0 0 1.78l6.924 6.823Z" fill="#000000"/></svg>
        </span>
      </div>
    </nav>
    <div class="menu vertical user-menu">
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import Logo from './Logo';
import PrimaryNavItem from './PrimaryNavItem';
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';

import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  props: {
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    backToApp(){
      window.location.href = "https://app.smsedge.com"
    },
    openDocs(){
     window.location.href = "https://doc.clickup.com/d/h/6arye-148/6d9a6a9e05f9ed2/6arye-7408" 
    }
  },
};
</script>
<style lang="scss" scoped>
.primary--sidebar {
  display: flex;
  flex-direction: column;
  width: var(--space-jumbo);
  border-right: 1px solid var(--s-50);
  box-sizing: content-box;
  height: 100%;
  flex-shrink: 0;
}

.menu {
  align-items: center;
  margin-top: var(--space-medium);
}

.user-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: var(--space-normal);
}

.button {
  margin: var(--space-small) 0;
}
</style>
